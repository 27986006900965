import styled from '@emotion/styled'
import Box from '@src/components/atoms/Box'
import HeaderWithImage from '@src/components/molecules/HeaderWithImage'
import { mediaQuery } from '@src/config/design'
import { colors as clrs } from '@src/theme/colors'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

const colors = {
  link: clrs.theme,
  linkHover: clrs.mapPin,
  code: '#777',
  border: '#333',
  pre: {
    text: '#555',
  },
}
const PageContent = (props: any) => (
  <Box>
    <PageContainer dangerouslySetInnerHTML={{ __html: props.content }} />
  </Box>
)

const PageContainerBox = styled.div`
  ${mediaQuery.forMobile} {
    width: 90%;
    margin: 0 auto;
  }
`
const PageContainer = styled.div`
  margin-bottom: 3rem;

  h1 {
    margin-top: 10rem;
  }
  ${mediaQuery.forMobile} {
    h1 {
      margin-top: 5rem;
    }
  }

  h2,
  h3 {
    margin: 3.5rem 0 1.7rem;
  }

  ol {
    font-size: 1.2rem;
    margin-bottom: 1.8em;
    margin-top: 0.4em;
    padding-left: 2em;
    li {
      padding-left: 0.5em;
      line-height: 1.6;
    }
  }

  ul {
    font-size: 1.2rem;
    margin-bottom: 1.8em;
    margin-top: 0.4em;
    padding-left: 2em;
    // list-style: none;
    li {
      padding-left: 0.5em;
      line-height: 1.6;
    }
    // li::before {
    //   content: "•";
    //   opacity: 1;
    //   margin-right: 0.5em;
    //   margin-left: -.55em;
    // }
  }

  a {
    ${mediaQuery.forPhoneOnly} {
      font-weight: 500;
    }
  }

  .gatsby-resp-image-link {
    background-image: none;
    :hover {
      background-image: none;
    }
  }

  p {
    line-height: 1.44
    font-size: 1.4rem
    code {
      padding-left: 0.2em;
      border: 1px solid ${colors.code};
      padding-right: 0.2em;
    }
  }

  blockquote {
    margin-top: 4em;
    font-size: 90%;
    padding: 1.5em 1em 1.5em 0.5em;
    margin-bottom: 0;
    font-size: 1em;
    border-top: 2px solid ${colors.border};
    border-bottom: 2px solid ${colors.border};
    position: relative;
    margin-bottom: 4em;
    p {
      margin-bottom: 0;
    }

    cite {
      float: right;
      font-weight: 400;
      padding: 0.3em;
    }
  }

  blockquote + p {
    margin-top: 2em;
  }

  pre {
    font-size: 0.85em;
    color: ${colors.pre.text};
    padding: 0 1em 0;
    border-left: 2px solid ${colors.border};
    margin-bottom: 2em;
    overflow-x: auto;
  }
`

export const DocumentPageTemplate = ({ title, content, description }: any) => (
  <section className="DocumentPage">
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
    <HeaderWithImage>
      {/* <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{title}</h2> */}
    </HeaderWithImage>
    <PageContainerBox>
      <PageContent className="content" content={content} />
    </PageContainerBox>
  </section>
)

export default (props: any) => {
  // console.log(props)
  const { markdownRemark: post } = props.data

  return (
    <DocumentPageTemplate
      // title={post.frontmatter.title}
      // description={post.frontmatter.description}
      content={post.html}
    />
  )
}

export const documentPageQuery = graphql`
  query DocumentPage($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
  }
`
