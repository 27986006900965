const mediaSize = {
  phoneUp: 600,
  tabletPortraitUp: 900,
  tabletLandscapeUp: 1200,
  desktopUp: 1800,
}
const mediaQuery = {
  forPhoneOnly: '@media (max-width: 599px)',
  forMobile: '@media (max-width: 899.9px)',
  forTabletPortraitUp: '@media (min-width: 600px)',
  forTabletLandscapeUp: '@media (min-width: 900px)',
  forDesktopUp: '@media (min-width: 1200px)',
  forBigDesktopUp: '@media (min-width: 1800px)',
}
const fonts = {
  default: 'Mplus 1p,Open Sans,Roboto,Helvetica,sans-serif',
  // default: '"Mplus 1p","Open Sans",Roboto,Helvetica,sans-serif',
}

export { mediaSize, mediaQuery, fonts }
